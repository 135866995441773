import React from 'react';
import {getUserAnswers, getUserTime} from "../JS/QandA";

export function ShowAnswers(props){
    props.handleLocal(0);//nastaví hodnotu na 0
    const queryParams = new URLSearchParams(window.location.search);
    getUserAnswers(queryParams.get('login'));//získá data z databáze podle loginu
    getUserTime(queryParams.get('login'));//získá čas z databáze
    return (
        <div style={{margin: "2rem"}}>
                <div className="row">
                    <div className="col-sm-12 text-center">
                        <h1 className="generalTitleStyle">Výpis zvolených odpovědí</h1>
                    </div>
                </div>
                <div id="Question" className="row">
                </div>
                <div className="text-center m-2" id="time">
                </div>
        </div>
    );
}