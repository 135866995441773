import React from "react";

import ReactWOW from "react-wow";
import sticker21 from "../obrazky/benefits-21.png";

export function BP_PrinciplesPL(props){
    props.handleLocal(8);
    let $ = require('jquery');
    function mouseMoveOut() {
        if(!document.getElementById("buttonID").classList.contains("nextButtonMouseMoveOut")) {
            document.getElementById("buttonID").setAttribute("class", "nextButton nextButtonPrinciples nextButtonMouseMoveOut");
            setTimeout(function () {
                    props.handleChange(28);
                },1300);
        }
    }
    $(document).ready(function () {
        $(".principlesPlButton").click(function () {
            $(".principlesPLul").css("visibility", "visible").addClass("fadeInUpCustom");
            $(".principlesPlButton").removeClass("pulseCustom").addClass("fadeOutCustom");
            setTimeout(function () {
                $(".principlesPlButton").css("visibility", "hidden")
            }, 2000)
        })
    });
    document.addEventListener('keydown',nextPage);
    function nextPage(e){
        if(e.keyCode === 39 && localStorage.getItem('OBPV') === "8"){
            mouseMoveOut();
        }
    }
    return(

        <div className="pageCon backgroundDC">
            <div className="container-fluid containerCenterFixed p-5">
                <div className="row">
                    <div className="col-sm-12 text-center mb-4">
                        <ReactWOW animation="fadeInDown">
                            <h2 className="text-uppercase generalTitleStyle">bluepool principles</h2>
                        </ReactWOW>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-2"/>
                    <div className="col-sm-4 text-center">
                        <ReactWOW animation="fadeInLeft">
                            <img src={sticker21} className="ESstickerMG" alt="Project life sticker"/>
                        </ReactWOW>
                    </div>
                    <div className="col-sm-4 text-center">
                        <div className="ESparagStyle">
                            <ReactWOW animation="fadeIn" delay="900ms">
                                <h4 className="EStitle ESparagStyleTitle text-uppercase">project life</h4>
                                <ul className="text-justify">
                                    <li className="principlesParagStyle">
                                        Naší prací je hlavně služba klientovi. Tak to je.
                                        Klient by měl být vždy spokojený s naším výkonem, chováním, jednáním i vzhledem.
                                        Na tom si zakládáme.
                                    </li>
                                    <li className="principlesParagStyle">
                                        Chodíš <b>včas</b> a je na Tebe <b>spolehnutí</b>. Za každého počasí.
                                    </li>
                                    <li className="principlesParagStyle">
                                        Průšvih se stane každému z nás, to je život, ale je potřeba to <b>hned sdílet
                                        svému šéfovi v
                                        BluePool</b>, aby byl prostor na vyžehlení.
                                    </li>
                                    <li className="principlesParagStyle">
                                        Na projektu pracujeme <b>POUZE</b> pro klienta.
                                        Můžou nastat chvíle, kdy je prostoj a není takzvaně do čeho píchnout.
                                        Ani v takové chvíli ale nevytáhneš PC nebo nehraješ hry. Radši se dojdi zeptat,
                                        zda nemůžeš <b>pomoct</b> s něčím dalším nebo se pokus seznámit s prostředím u
                                        klienta. Získáš tak body a klient to ocení.
                                    </li>
                                </ul>
                            </ReactWOW>
                        </div>
                    </div>
                    <div className="col-sm-2 text-center">
                        <button type="button" id="buttonID" onClick={() => {mouseMoveOut()}} className="nextButton nextButtonPrinciples">
                            <svg id="arrow-forward" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                                <path id="Shape" d="M11.5,0,9.488,2.013l8.05,8.05H0v2.875H17.538l-8.05,8.05L11.5,23,23,11.5Z"
                                      fill="#42A8D6"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}