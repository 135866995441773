import React from "react";
import ReactWOW from 'react-wow';

export function BP_KeyWords(props){
    props.handleLocal(3);
    function mouseMoveOut() {
        if(!document.getElementById("buttonID").classList.contains("nextButtonHistoryMoveOut")) {
            document.getElementById("buttonID").setAttribute("class", "nextButton nextButtonPrinciples nextButtonHistoryMoveOut");
            setTimeout(function () {
                props.handleChange(18);
            }, 1300);
        }
    }
    let $ = require('jquery');
    function changeState(button, item){//metoda pro zobrazení/skrytí popisků
        if(!item.hasClass("fadeInDownCustom")){//pokud nemá item classu fadeInDownCustom, znamená, že je skrytý
            item.removeClass("fadeOutUpCustom");//odendáme classu
            item.css("visibility", "visible").addClass("fadeInDownCustom");//zapneme zobrazení popisku a přidáme mu třídu
            button.removeClass("pulseCustom");//odendáme tlačítku classu pulseCustom, tlačítko přestane pulzovat
        }else{//pokud je popisek již zobrazen, vypneme ho
            item.removeClass("fadeInDownCustom");//odendáme classu
            item.addClass("fadeOutUpCustom");//přidáme příspěvku classu pro provedení animace směrem vzhůru do ztracena
            setTimeout(function(){//počkáme 2 vteřiny
                item.css("visibility","hidden");}//skryjeme příspěvek
                ,2000
            );
            button.addClass("pulseCustom");//přidáme opět tlačítku classu, aby pulzovalo
        }
    }
    $(document).ready(function () {
        $(".BTCHbutton").click(function () {//když uživatel klikne na tlačítko DB (Drive Blue)
            changeState($(".BTCHbutton"),$(".keyWordsUlHide"));
        });

        $(".interniVyvojBTN").click(function () {//když uživatel klikne na tlačítko Interní vývoj
            changeState($(".interniVyvojBTN"),$(".interniVyvojUlHide"));
        });

        $(".bbcBTN").click(function () {//když uživatel klikne na tlačítko BBC (Brand Blue Care)
            changeState($(".bbcBTN"),$(".bbcUlHide"));
        });

        $(".ptpBTN").click(function () {//když uživatel klikne na tlačítko PT pool (part-time pool)
            changeState($(".ptpBTN"),$(".ptpUlHide"));
        })

        $(".workBTN").click(function () {//když uživatel klikne na tlačítko Work
            changeState($(".workBTN"),$(".workUlHide"));
        });

        $(".btchBTN").click(function () {//když uživatel klikne na tlačítko BTCH (Business Trip Challenge)
            changeState($(".btchBTN"),$(".btchUlHide"));
        })
    });
    document.addEventListener('keydown',nextPage);
    function nextPage(e){
        if(e.keyCode === 39 && localStorage.getItem('OBPV') === "3"){
            mouseMoveOut();
        }
    }
    return (
        <div className="pageCon BP_BG">
            <div className="container-fluid containerCenterFixed">
                <div className="row">
                    <div className="col-sm-2"/>
                    <div className="col-sm-8">
                        <div className="row">
                            <div className="col-sm-12 mb-4 text-center">
                                    <ReactWOW animation="fadeIn">
                                    <h2 className="generalTitleStyle text-uppercase">keywords</h2>
                                </ReactWOW>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 text-center">
                                    <h4 className="generalSubTitleStyle pulseCustom bbcBTN">BBC (Brand Blue Care)</h4>
                                    <ul className="keyWordsUL">
                                        <li className="generalParagStyle text-justify bbcUlHide">
                                        Tým, který pečuje o spokojenost všech našich lidí. Ať už jde o pracovní prostředí,
                                        společné akce nebo vzdělávání, vždycky za tím hledej kolegu z BBC.
                                        </li>
                                    </ul>
                            </div>
                            <div className="col-sm-6 text-center">
                                <h4 className="generalSubTitleStyle pulseCustom btchBTN">BTCH (Business Trip Challenge)</h4>
                                <ul className="keyWordsUL ">
                                    <li className="generalParagStyle text-justify btchUlHide">
                                        Jeden z našich dalších benefitů, celoroční soutěž, jejíž pravidla a informace o ní nalezneš také ve Worku.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 text-center">
                                    <h4 className="generalSubTitleStyle pulseCustom interniVyvojBTN">Interní vývoj</h4>
                                    <ul className="keyWordsUL">
                                        <li className="generalParagStyle text-justify interniVyvojUlHide">
                                            Volně se měnící parta kolegů, kteří pracují na vývoji našich interních systémů a zakázek. Pokud
                                            zrovna nehájí naše barvy u klienta.
                                        </li>
                                    </ul>
                            </div>

                            <div className="col-sm-6 text-center">
                                    <h4 className="generalSubTitleStyle pulseCustom BTCHbutton">DB (DriveBlue)</h4>
                                    <ul className="keyWordsUL">
                                        <li className="generalParagStyle text-justify keyWordsUlHide">
                                            Naše cafeterie, kterou nalezneš ve Worku, našem interním systému. Na výběr je toho tam mnoho, více se o DriveBlue dočteš dále.
                                        </li>
                                    </ul>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 text-center">
                                    <h4 className="generalSubTitleStyle pulseCustom workBTN">IS Work</h4>
                                    <ul className="keyWordsUL">
                                        <li className="generalParagStyle text-justify workUlHide">
                                        Najdeš zde novinky, znalostní bázi, články na blogu.
                                        Je to zároveň místo, kde si vykážeš docházku nebo zažádáš o dovolenou. Pokud budeš hledat kontakt na jakéhokoli kolegu, najdeš ho ve Worku.
                                        </li>
                                    </ul>
                            </div>

                            <div className="col-sm-6 text-center">
                                <h4 className="generalSubTitleStyle pulseCustom ptpBTN">PT pool (part-time pool)</h4>
                                <ul className="keyWordsUL">
                                    <li className="generalParagStyle text-justify ptpUlHide">
                                        Tým kolegů, kteří ještě studují a tudíž se nemohou věnovat konzultantské činnosti na 100 %. V PT
                                        poolu se seznamují s životem konzultanta ještě před skončením studia, aby si ujasnili, že tohle je
                                        pro ně to pravé.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-2">
                        <button type="button" id="buttonID" onClick={() => {mouseMoveOut()}} className="nextButton nextButtonHistory">
                            <svg id="arrow-forward" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                                <path id="Shape" d="M11.5,0,9.488,2.013l8.05,8.05H0v2.875H17.538l-8.05,8.05L11.5,23,23,11.5Z"
                                      fill="#42A8D6"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}