import React from 'react';
import { getQnA, postAnswers } from '../JS/QandA';

export function BP_QuizEmployeeSweets(props){
    props.handleLocal(19);
    getQnA(2);
    function nextPage(){//metoda pro načtení další stárnky
        if(postAnswers(2)) {//odešle data na vyhodnocení, pokud metoda vrátí true, načte se další stránka
            setTimeout(function () {
                props.handleChange(10);//načte další stránku
            }, 1300);
        }
    }
    document.addEventListener('keydown',keyClick);
    function keyClick(e){
        if(e.keyCode === 39 && localStorage.getItem('OBPV') === "19"){
            nextPage();
        }
    }
    return (
        <div className="pageCon Kviz_BG">
            <div className="container-fluid containerCenterFixed p-5">
                <div className="row">
                    <div className="col-sm-12 text-center mb-2">
                        <h1 className="generalTitleStyle">Quiz</h1>
                        <h4 className="generalSubTitleStyle">Employee Sweets</h4>
                    </div>
                </div>
                <div id="Question" className="row">
                </div>
                <div id="AnswerRow" className="row">
                </div>
                <div className="row">
                    <div className="col-sm-12 text-center mt-5">
                        <button type="submit" className="buttonQForm" onClick={() => nextPage()}>Odeslat</button>
                    </div>
                </div>
            </div>
        </div>
    );
}