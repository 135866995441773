import React from "react";

import ReactWOW from 'react-wow';
import benefit1 from "../obrazky/benefits-06.png";

export function BP_EmployeeSweetsDB(props){
    props.handleLocal(4);
    function mouseMoveOut() {
        if (!document.getElementById("buttonID").classList.contains("nextButtonHistoryMoveOut")) {
            document.getElementById("buttonID").setAttribute("class", "nextButton nextButtonHistory nextButtonHistoryMoveOut");
            setTimeout(function () {
                props.handleChange(17);
            }, 1300);
        }
    }
    document.addEventListener('keydown', nextPage);
    function nextPage(e){
        if(e.keyCode === 39 && localStorage.getItem('OBPV') === "4"){
            mouseMoveOut();
        }
    }
    return (
        <div className="pageCon backgroundHistoryCorner2">
            <div className="container-fluid containerCenterFixed">
                <div className="row">
                    <div className="col-sm-12 text-center">
                        <ReactWOW animation='fadeInDown' delay="200ms">
                            <h2 className='generalTitleStyle text-uppercase mb-4'>Employee sweets</h2>
                        </ReactWOW>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-2"/>
                    <div className="col-sm-4 text-center">
                        <div className="imgCenteredDB">
                            <ReactWOW animation='fadeInLeft'>
                                    <img src={benefit1} className="ESDBsticker" alt=""/>
                            </ReactWOW>
                        </div>
                    </div>
                    <div className="col-sm-4 text-center">
                        <div className="ESparagStyleDB">
                            <ReactWOW animation="fadeIn" delay="900ms">
                                <h4 className="EStitle text-uppercase">DriveBlue</h4>
                                <ul className="text-justify">
                                    <li className="ESparagStyle">
                                        Cafeterie, ve které si můžeš vybrat benefit dle libosti. Jelikož všichni rádi pomáháme, tak jedna z možností, jak utratit své kredity je podpora zvířat.
                                        Ať už psů, králíků, hlodavců nebo je možné příspět celkově spolku Zvíře v nouzi.
                                    </li>
                                    <li className="ESparagStyle">
                                        A aby toho nebylo málo, za každý tvůj kredit, který přispěješ, přihodíme jednou tolik.
                                        Je to náš nejoblíbenější benefit. Když ale nenajdeš, co jsi zrovna hledal, umíme to doplnit. Stačí dát vědět BBC.
                                    </li>
                                    <li className="ESparagStyle">
                                        A jak získat kredity? Jednou za čtvrt roku dostaneš kredity jako poděkování za to, že jsi s námi. Jak ale získat více kreditů? Dozvíš se na další straně.
                                    </li>
                                </ul>
                            </ReactWOW>
                        </div>
                    </div>
                    <div className="col-sm-2">
                        {/*<ReactWOW animation='fadeIn' delay="500ms">*/}
                            <button id="buttonID" type="button" onClick={() => {mouseMoveOut()}} className="nextButton nextButtonHistory">
                                <svg id="arrow-forward" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                                    <path id="Shape" d="M11.5,0,9.488,2.013l8.05,8.05H0v2.875H17.538l-8.05,8.05L11.5,23,23,11.5Z"
                                          fill="#42A8D6"/>
                                </svg>
                            </button>
                        {/*</ReactWOW>*/}
                    </div>
                </div>
            </div>
        </div>
    );
}