import React from 'react';
import ReactWOW from 'react-wow';

import bp_manifest from "../obrazky/BP_Manifest.png";

export function BP_Manifest(props){
    props.handleLocal(1);
    function mouseMoveOut() {
        if(!document.getElementById("buttonID").classList.contains("nextButtonManifestMoveOut")) {
            document.getElementById("buttonID").setAttribute("class", "nextButton nextButtonManifest nextButtonManifestMoveOut");
            setTimeout(function () {
                props.handleChange(2);
                }, 1300);
        }
    }
    document.addEventListener('keydown', nextPage);
    function nextPage(e){
        if(e.keyCode === 39 && localStorage.getItem('OBPV') === "1"){
            mouseMoveOut();
        }
    }
    return (
    <div className="pageCon backgroundHistoryCorner2 wave1" /*onLoad={() => {createUser()}}*/>
        <div className="container-fluid containerCenterFixed">
            <div className="row">
                <div className="col-sm-2"/>
                <div className="col-sm-8">
                    <div className="row">
                        <div className="col-sm-6 text-center bp_manifestCenter">
                            <ReactWOW animation="fadeInLeft" delay="200ms">
                                <h2 className="generalTitleStyle text-uppercase mb-4">welcome on board</h2>
                                <p className="manifestParag text-center">Dnes je Tvůj první den v BluePoolu. Jsme moc rádi, že jsi součástí našeho týmu a doufáme, že se Ti s námi bude líbit.</p>
                                <p className="manifestParag text-center">Určitě ses toho o nás už hodně dozvěděl z našeho webu a blogu. Půjdeme se spolu podívat na to důležité, na věci, které bys měl o nás vědět.</p>
                                <p className="manifestParag text-center">Během onboardingu Tě čeká několik otázek na zopakování. Ale neděs se, žádná odpověď není správná ani špatná. Vše s Tebou ještě jednou probereme.</p>
                            </ReactWOW>
                        </div>
                        <div className="col-sm-6">
                            <ReactWOW animation="fadeInRight" delay="200ms">
                                <img src={bp_manifest} className="bp_manifestStyle" alt=""/>
                            </ReactWOW>
                        </div>
                    </div>
                </div>
                <div className="col-sm-2">
                    <button type="button" id="buttonID" onClick={() => {mouseMoveOut()}} className="nextButton nextButtonManifest">
                        <svg id="arrow-forward" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                            <path id="Shape" d="M11.5,0,9.488,2.013l8.05,8.05H0v2.875H17.538l-8.05,8.05L11.5,23,23,11.5Z" fill="#42A8D6"/>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
    )
}