import React from "react";

import sticker1 from "../obrazky/Sticker1.png";
import ReactWOW from "react-wow";

export function BP_Principles(props){
    props.handleLocal(10);
    function mouseMoveOut() {
        if(!document.getElementById("buttonID").classList.contains("nextButtonMouseMoveOut")) {
            document.getElementById("buttonID").setAttribute("class", "nextButton nextButtonPrinciples nextButtonMouseMoveOut");
            setTimeout(function () {
                props.handleChange(27);
            }, 1300);
        }
    }
    document.addEventListener('keydown',nextPage);
    function nextPage(e){
        if(e.keyCode === 39 && localStorage.getItem('OBPV') === "10"){
            mouseMoveOut();
        }
    }
    return(
        <div className="pageCon backgroundDC">
            <div className="container-fluid containerCenterFixed p-5">
                <div className="row">
                    <div className="col-sm-12 text-center mb-4">
                        <ReactWOW animation="fadeInDown">
                            <h2 className="text-uppercase generalTitleStyle">bluepool principles</h2>
                        </ReactWOW>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-2"/>
                    <div className="col-sm-4 text-center">
                        <ReactWOW animation="fadeInLeft">
                            <img src={sticker1} className="ESstickerMG ESstickerMGCenter" alt="Be in touch. All the time sticker"/>
                        </ReactWOW>
                    </div>
                    <div className="col-sm-4 text-center">
                        <div className="ESparagStyle">
                            <ReactWOW animation="fadeIn" delay="900ms">
                                <h4 className="EStitle ESparagStyleTitle text-uppercase">Be in touch</h4>
                                <ul className="text-justify">
                                    <li className="principlesParagStyle">
                                        Z povahy naší práce je potřeba být na příjmu. Hangouts je stěžejní komunikační nástroj napříč celou firmou,
                                        ujisti se, že jsi <b>ON-LINE</b>.
                                        {/*Když Ti pípne, tak to znamená, že po Tobě někdo něco chce a odpověď asi rychle potřebuje.*/}
                                        {/*Reaguj prosím <b>ASAP</b>.*/}
                                    </li>
                                    <li className="principlesParagStyle">
                                        Na maily ale rozhodně nerezignujeme, i když třeba tolik nespěchají.
                                        Čti poštu zn. denně, ráno /v poledne/ večer. Na pozvánky vždy odpovídej, je to důležité pro naše plánování.
                                        „MOŽNÁ“ <b>není</b> odpověď, podle možná rezervaci bohužel neuděláme.
                                    </li>
                                    <li className="principlesParagStyle">
                                        Na mail také chodí pravidelné <b>pondělní emaily</b>, kde informujeme o tom,
                                        co nového se děje/dít bude. Na tyto emaily není nutné odpovídat, pokud se v nich na nic neptáme.
                                    </li>
                                </ul>
                            </ReactWOW>
                        </div>
                    </div>
                    <div className="col-sm-2 text-center">
                        <button type="button" id="buttonID" onClick={() => {mouseMoveOut()}} className="nextButton nextButtonPrinciples">
                            <svg id="arrow-forward" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                                <path id="Shape" d="M11.5,0,9.488,2.013l8.05,8.05H0v2.875H17.538l-8.05,8.05L11.5,23,23,11.5Z"
                                      fill="#42A8D6"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}