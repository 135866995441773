import React from "react";

import benefit2 from "../obrazky/benefits-03.png";
import benefit7 from "../obrazky/benefits-05.png";

export function BP_EmployeeSweetsStyleCareer(props){
    props.handleLocal(6);
    let $ = require('jquery');
    function mouseMoveOut() {
        if(!document.getElementById("buttonID").classList.contains("nextButtonHistoryMoveOut")) {
            document.getElementById("buttonID").setAttribute("class", "nextButton nextButtonPrinciples nextButtonHistoryMoveOut");
            setTimeout(function () {
                props.handleChange(19);
            }, 1300);
        }
    }
    function changeState(button, item){
        if(!item.hasClass("fadeInDownCustom")){
            item.removeClass("fadeInDownCustom");
            item.css("visibility","visible").addClass("fadeInDownCustom");
            button.removeClass("pulseCustom");
        }else{
            item.removeClass("fadeInDownCustom");
            item.addClass("fadeOutUpCustom");
            setTimeout(function(){
                item.css("visibility","hidden");},
                2000);
            button.addClass("pulseCustom");
        }
    }
    $(document).ready(function () {
        $(".ESstickerStylish").click(function () {
            changeState($(".ESstickerStylish"),$(".stylishLi"));
        });

        $(".ESstickerCareer").click(function () {
            changeState($(".ESstickerCareer"),$(".careerLi"));
            changeState($(".ESstickerCareer"),$(".careerLiSecond"));
        });
    });
    document.addEventListener('keydown',nextPage);
    function nextPage(e){
        if(e.keyCode === 39 && localStorage.getItem('OBPV') === "6"){
            mouseMoveOut();
        }
    }
    return (
        <div className="pageCon backgroundES">
            <div className="container-fluid containerCenterFixed">
                <div className="row">
                    <div className="col-sm-12 text-center">
                        <h2 className="generalTitleStyle text-uppercase mb-4">Employee sweets</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-2"/>
                    <div className="col-sm-4 text-center">
                        <h4 className="EStitle mb-4 text-uppercase">Stylish just for you</h4>
                        <img src={benefit2} className="ESsticker ESstickerStylish pulseCustom" alt=""/>
                            <ul className="text-justify">
                                <li className="ESparagStyle stylishLi">
                                    Pro práci konzultanta je potřeba nejenom technických dovedností, ale i odpovídající dress code. To u nás není žádný problém. Rádi Ti s tím pomůžeme.
                                </li>
                            </ul>
                    </div>

                    <div className="col-sm-4 text-center">
                        <h4 className="EStitle mb-4 text-uppercase">Career</h4>
                        <img src={benefit7} className="ESsticker ESstickerCareer pulseCustom" alt=""/>
                        <ul className="text-justify">
                            <li className="ESparagStyle careerLi">
                                Míříš vysoko? U nás můžeš, jen nám o tom musíš říct – rádi Tě podpoříme a nastavíme společně plán, jak se tam dostat. Stačí jen chtít a dát tomu trochu snahy, samo to úplně nepůjde.
                                A to se týká nejen kariérního růstu.
                            </li>
                            <li className="ESparagStyle careerLiSecond">
                                Naši kolegové o tom vědí své. Jejich zkušenosti si můžeš přečíst na našem <a rel="noopener noreferrer" href="https://blue-pool.cz/kariera.php" className="linkCareer" target="_blank">webu</a>.
                            </li>
                        </ul>
                    </div>
                    <div className="col-sm-2">
                        <button type="button" id="buttonID" onClick={() => {mouseMoveOut()}} className="nextButton nextButtonNWO">
                            <svg id="arrow-forward" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                                <path id="Shape" d="M11.5,0,9.488,2.013l8.05,8.05H0v2.875H17.538l-8.05,8.05L11.5,23,23,11.5Z"
                                      fill="#42A8D6"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}