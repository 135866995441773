let $ = require('jquery');

export function getQnA(section) {//metoda na získání otázek a možných odpovědí
    $(document).ready(function () {
        let settings = {
            "url": "https://onboarding.blue-pool.cz/back_end/api_handler.php",
            "method": "GET",
            "timeout": 0,
            "headers": {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            "data": {
                "section": section,
                "type": "questions"
            }
        };
        $.ajax(settings).done(function (res) {
            let data = JSON.parse(res);//převede string data z Jsnu do javascriptu
            prepareForm(data);//vygeneruje formulář
        });

        function prepareForm(data) {
            let numOfQuestions = data.length;//získá celkový počet otázek

            for (let j = 0; j < numOfQuestions; j++) {//projde všechny otázky

                let numOfOptions = data[j].Answers.length;//získá počet odpovědí ke každé otázce

                let colQ = document.createElement("div");//vytvoří element div
                if(section === 1){
                    colQ.setAttribute("class", "col-sm-6");//pokud se jedná o první sekci, přidá divu rozdělení na 6 bloků
                }else{
                    colQ.setAttribute("class", "col-sm-4");//pokud se jedná o jinou než první sekci, přidá divu rozdělení na 4 bloky
                }

                let colQH = document.createElement("h4");//vytvoří nadpis
                colQH.setAttribute("class", "generalSubTitleStyle text-left mt-3 ml-3");//přidá vlastnosti nadpisu

                colQH.innerHTML = data[j].Question;//vloží do nadpisu text otázky
                colQ.appendChild(colQH);//vloží nadpis do divu
                if(j < 3) {
                    $("#Question").append(colQ);//když je maximálně 3. otázka, vloží div do divu s id Questions
                }else{
                    $("#Question2").append(colQ);//když je více jak 3. otázka, vloží div do divu s id Questions2
                }

                //vytvoření columns

                let colA = document.createElement("div");//vytvoří nový div
                if(section === 1) {
                    colA.setAttribute("class","col-sm-6 pl-5");//pokud se jedná o první sekci, přidá divu rozdělení na 6 bloků
                }else{
                    colA.setAttribute("class", "col-sm-4 pl-5");//pokud se jedná o jinou než první sekci, přidá divu rozdělení na 4 bloky
                }
                if(j < 3) {
                    $("#AnswerRow").append(colA);//když je maximalně 3. otázka, vloží div do divu s id AnswerRow
                }else{
                    $("#AnswerRow2").append(colA);//když je více jak 3. otázka, vloží div do divu s id AnswerRow2
                }

                for (let i = 0; i < numOfOptions; i++) {//projde všechny možné odpovědi k danné otázce

                    let rowA = document.createElement("div");//založí div
                    rowA.setAttribute("class","row");//přidá vlastnosti divu
                    colA.append(rowA);//vloží nově založený div do divu výše

                    let checkboxBtn = document.createElement("input");//založí element typu input
                    checkboxBtn.setAttribute("type", "checkbox");//přidá typ inputu - checkbox
                    checkboxBtn.setAttribute("class", "checkbox-custom chck_" + j);//přidá třídu
                    checkboxBtn.setAttribute("name",data[j].Answers[i]);//vyplní název checkboxu
                    colA.append(checkboxBtn)//checkbox vloží do divu

                    let label = document.createElement("label");//vytvoří label
                    label.append(data[j].Answers[i]);//vloží do labelu text
                    label.onclick = (function(otp){
                        return function(){
                            clickOnCheckbox(otp);
                        };
                    })(data[j].Answers[i]);

                    colA.append(label);//label vloží do divu

                }
            }
        }
    });
}

export function getUserAnswers(login){//získá odpovědi konkrétního uživatele
    $(document).ready(function(){
        let settings = {
            "url": "https://onboarding.blue-pool.cz/back_end/api_handler.php",
            "method": "POST",
            "timeout": 0,
            "headers": {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            "data": {
                "section": 1,
                "type": "getUserAnswers",
                "userID": login
            },
        };
        $.ajax(settings).done(function(res){
            let data = JSON.parse(res);//data ze serveru převede do Javascrpt pole
            showAnswers(data);//zavolá metodu pro vykreslení odpovědí
        });
        function showAnswers(data){
            let numsOfQuestion = data.length;//získá celkový počet záznamů

            for(let j = 0; j < numsOfQuestion; j ++){//projde všechny záznamy
                let colQ = document.createElement("div");//vytvoří div
                colQ.setAttribute("class", "col-sm-3 border border-black");//přidá vlastnosti divu

                let colQH = document.createElement("h4");//vytvoří nadpis
                colQH.setAttribute("class","text-center");//přidá vlastnosti nadpisu
                colQH.innerHTML = data[j].Question;//vloží text do nadpisu
                colQ.appendChild(colQH);//vloží nadpis do divu

                for(let k = 0; k < data[j].Answers.length; k++){//projde všechy odpovědi
                    let colQp = document.createElement("p");//vytvoří element p
                    colQp.setAttribute("class","text-center bottom-align-text");//nastaví vlastnosti elementu
                    colQp.innerHTML = data[j].Answers[k].text;//vloží do elementu p text
                    colQ.appendChild(colQp)//vloží element p do divu
                }

                $("#Question").append(colQ);//vloží div do divu s id Question
            }
        }
    });
}

export function getUserTime(login){//získá čas strávený na onboarding appce
    $(document).ready(function(){
        let settings = {
            "url": "https://onboarding.blue-pool.cz/back_end/api_handler.php",
            "method": "POST",
            "timeout": 0,
            "headers":{
                "Content-Type": "application/x-www-form-urlencoded"
            },
            "data": {
                "section": 1,
                "type": "getUserTime",
                "userID": login
            }
        }
        $.ajax(settings).done(function(res){
            let data = JSON.parse(res);//převede data ze serveru do javascript pole
            GetDuration(data);//zavolá metodu pro vykreslení času
        });
        function GetDuration(data){

            const start = new Date(data["start"]);//proměnná pro datum spuštění
            const end = new Date(data["end"]);//proměnná pro datum ukončení

            let colP = document.createElement("p");//vytvoří element p
            let difference = Math.abs(start - end) / 1000;//získá rodzíl mezi časy v milisekundách

            let hours = Math.floor(difference / 3600) % 24;//získá hodiny

            let minutes = Math.floor(difference / 60) % 60;//získá minuty

            if(minutes < 10){//pokud jsou minuty menší jak 10, připíše se před ně 0, aby odpovídali formátování
                minutes = "0" + minutes;
            }

            colP.innerHTML = "Strávený čas: " + hours + ":" + minutes + " min";//vloží text do elementu p
            $("#time").append(colP);//vloží element p do divu time
        }
    });
}

export function getAllQuestions(){//získá všechny otázky
    $(document).ready(function(){
        let settings = {
            "url": "https://onboarding.blue-pool.cz/back_end/api_handler.php",
            "method": "GET",
            "timeout": 0,
            "headers": {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            "data": {
                "section": 1,
                "type": "getAllQuestions"
            }
        }
        $.ajax(settings).done(function(res){
            console.log(res);
            let data = JSON.parse(res);//převede odpověď serveru na javascriptový pole
            ShowQuestions(data);//zavolá metodu pro vykreslení otázek
        });

        function ShowQuestions(data){
            for(let k = 0; k < data.length; k ++){//projde všechny otázky
                let div = document.createElement("div");//vytvoří div
                div.setAttribute("class","col-sm-3 border border-black");//dá vlastnosti divu

                let head = document.createElement("h4");//vytvoří nadpis
                head.setAttribute("class","text-center");//dá vlastnisti nadpisu
                head.innerHTML = data[k].question;//vloží text do připraveného nadpisu
                div.appendChild(head);//vloží nadpis do divu

                for(let d = 0; d < data[k].answers.length; d++){//projde všechny správné odpovědi u otázky
                    let txt = document.createElement("p");//vytvoří odstavec
                    txt.setAttribute("class","text-center bottom-align-text text-success");//dá vlastnosti odstavci
                    txt.innerHTML += data[k].answers[d].text;//voží do odstavce text odpovědi
                    div.appendChild(txt);//vloží odstavec s odpovědí do divu
                }
                $("#Question").append(div);//vloží celý div do divu s id Question
            }
        }
    });
}

export function postAnswers(section) {//odešle odpovědi uživatele
    let checkboxes = document.getElementsByClassName("checkbox-custom");//získá všechny checkboxy
    let result = true;//proměnná, která určí zda-li jsou vyplněné všechny sekce otázek
    let num;//počet sekcí otázek
    switch(section){//podle sekce se uričí kolik otázek má každá sekce
        case 1: num = 2;break;
        case 2:num = 3;break;
        case 3: num = 3;break;
        case 4: num = 6;break;
        default: num = 0;break;
    }
    for(let i = 0; i < num; i ++){//podle počtu sekcí otázek se zopakuje for
        let res = false;//odkladová proměnná
        for(let j = 0; j < checkboxes.length; j ++){//projde všechny checkboxy v poli checkboxů
            if(checkboxes[j].classList.contains("chck_" + i)){//pokud chceckboxy mají classu chck
                if(checkboxes[j].checked){//pokud je checkbox zaškrtnut
                    res = true;//nastavíme odkladové proměnné hodnotu true
                    break;//pokud je alespoň jeden checkbox zaškrnut, přeskočí další kroky
                }
            }
        }
        if(res === false){//pokud odkladová proměnná je false, nastaví se false i výsledná proměnná
            result = false;
        }
    }

    if(result){//pokud jsou vyplněny všechny sekce
        let ch_answers = [];//vytvoří si pole s odpovědmi
        for (let i = 0; i < checkboxes.length; i++) {//projde všechny checkboxy v poli checkboxů
            if(checkboxes[i].checked){
                ch_answers.push(1);//pokud je zaškrtnuta odpověď
            }else{
                ch_answers.push(0);//pokud není zaškrtnuta odpověď
            }
        }
        let answers = JSON.stringify(ch_answers);//převede pole s odpovědmi na string pro JSON

        let settings = {
            "url": "https://onboarding.blue-pool.cz/back_end/api_handler.php",
            "method": "POST",
            "timeout": 0,
            "headers": {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            "data": {
                "section": section,
                "answers": answers,
                "type": "answers",
                "userID": localStorage.getItem("userID")
            }
        };

        $.ajax(settings).done(function (res) {

        });
    }else{//pokud sekce nejsou vyplněny
       alert("Prosím, vyplňte alespoň jednu odpověď u každé otázky");
    }
    return result;
}

function clickOnCheckbox(data){
    changeCheckboxState($("input[name='"+data+"']"));
}

function changeCheckboxState(checkbox){
    if(checkbox.prop("checked")){
        checkbox.prop("checked",false);
    }else{
        checkbox.prop("checked",true);
    }
}