import React from 'react';
import { getQnA, postAnswers } from '../JS/QandA';

export function BP_QuizPrinciples(props){
    props.handleLocal(20);
    getQnA(3);
    function nextPage(){//metoda pro načtení dalšá stránky
        if(postAnswers(3)) {//zavolá metodu, pokud metoda vrátí true, načte další stránku
            setTimeout(function () {
                props.handleChange(11);//načte další stránku
            }, 1300);
        }
    }
    document.addEventListener('keydown',keyClick);
    function keyClick(e){
        if(e.keyCode === 39 && localStorage.getItem('OBPV') === "20"){
            nextPage();
        }
    }
    return (
        <div className="pageCon Kviz_BG">
            <div className="container-fluid containerCenterFixed p-5">
                <div className="row">
                    <div className="col-sm-12 text-center mb-2">
                        <h1 className="generalTitleStyle">Quiz</h1>
                        <h4 className="generalSubTitleStyle">Principles</h4>
                    </div>
                </div>
                <div id="Question" className="row pl-5 pr-5">
                </div>
                <div id="AnswerRow" className="row pl-5 pr-5">
                </div>
                <div className="row">
                    <div className="col-sm-12 text-center mt-5">
                        <button type="submit" className="buttonQForm" onClick={() =>nextPage()}>Odeslat</button>
                    </div>
                </div>
            </div>
        </div>
    );
}