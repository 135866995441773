import React from "react";
import sticker from "../obrazky/palmaBP_sticker.png";
import ReactWOW from "react-wow";

export function BP_Basics(props){
    props.handleLocal(12);
    function mouseMoveOut() {
        if(!document.getElementById("buttonID").classList.contains("nextButtonBasicsMoveOut")) {
            document.getElementById("buttonID").setAttribute("class", "nextButtonPrinciples nextButton nextButtonBasicsMoveOut");
            setTimeout(function () {
                props.handleChange(13);
            }, 1300);
        }
    }
    document.addEventListener('keydown',nextPage);
    function nextPage(e){
        if(e.keyCode === 39 && localStorage.getItem('OBPV') === "12"){
            mouseMoveOut();
        }
    }
    return (
        <div className="pageCon backgroundHistoryCorner2">
            <div className="container-fluid containerCenterFixed p-5">
                <div className="row">
                    <div className="col-sm-12 text-center mb-4">
                        <ReactWOW animation="fadeInDown">
                            <h2 className="text-uppercase generalTitleStyle">Basics</h2>
                        </ReactWOW>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-2"/>
                    <div className="col-sm-4 text-center">
                        <ReactWOW animation="fadeInLeft">
                            <img src={sticker} className="ESstickerMG" alt="Basics sticker"/>
                        </ReactWOW>
                    </div>
                    <div className="col-sm-4 text-center">
                        <div className="ESparagStyle">
                            <ul className="text-justify">
                                <li className="principlesParagStyle">
                                    Work neboli firemní intranet obsahuje vše, co potřebuješ a co by Tě mohlo zajímat.
                                </li>
                                <li className="principlesParagStyle">
                                    Docházka se doplňuje poctivě a včas. Že se blíží poslední den v měsíci Ti připomene info mail. Když
                                    nevíme, kolik hodin jsi odpracoval, tak nevíme, kolik peněz Ti poslat, logické že?
                                </li>
                                <li className="principlesParagStyle">
                                    Číslo Tvého manažera najdeš ve Worku. Ulož si ho. Je důležité abys v připadě potřeby věděl, na koho se obrátit.
                                </li>
                                <li className="principlesParagStyle">
                                    Dovolenou hlaš s předstihem a nezadávej zpětně. Půl den není problém, jen uveď do poznámky informaci od-do, nebo dopoledne/odpoledne.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-2 text-center">
                        <button type="button" id="buttonID" onClick={() => {mouseMoveOut()}} className="nextButton nextButtonPrinciples">
                            <svg id="arrow-forward" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                                <path id="Shape" d="M11.5,0,9.488,2.013l8.05,8.05H0v2.875H17.538l-8.05,8.05L11.5,23,23,11.5Z"
                                      fill="#42A8D6"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        /*<div className="pageCon backgroundHistoryCorner2">
            <div className="container containerCenterFixed">
                <div className="row">
                    <div className="col-sm-1"/>
                    <div className="col-sm-10">
                        <div className="row">
                            <div className="col-sm-12 mb-2 text-center">
                                <ReactWOW animation="fadeInDown">
                                    <h2 className="generalTitleStyle text-uppercase mt-3">basics</h2>
                                </ReactWOW>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-5">
                            <h4 className="generalSubTitleStyle">Kde si vykázat docházku? Kde si zadat dovolenou?</h4>
                            <p className="basicText">Work neboli firemní intranet obsahuje vše, co potřebuješ a co by Tě mohlo zajímat.</p>
                        </div>
                        <div className="col-sm-2"/>
                        <div className="col-sm-5">
                            <h4 className="generalSubTitleStyle">Docházka a dovolená jsou dvě D, která si prosím pamatuj.</h4>
                            <p className="basicText">Docházka se doplňuje poctivě a včas. Že se blíží poslední den v měsíci Ti připomene info mail. Když
                                nevíme, kolik hodin jsi odpracoval, tak nevíme, kolik peněz Ti poslat, logické že?</p>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-sm-5"/>
                        <div className="col-sm-2">
                            <ReactWOW animation="fadeIn">
                                <img src={sticker} className="it_stickerBasics" alt="basics sticker" />
                            </ReactWOW>
                        </div>
                        <div className="col-sm-5">
                                <button type="button" id="buttonID" onClick={() => {mouseMoveOut()}} className="nextButton buttonBasicsGrid">
                                    <svg id="arrow-forward" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                                        <path id="Shape" d="M11.5,0,9.488,2.013l8.05,8.05H0v2.875H17.538l-8.05,8.05L11.5,23,23,11.5Z"
                                              fill="#42A8D6"/>
                                    </svg>
                                </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-5">
                            <h4 className="generalSubTitleStyle">Kde najít to číslo na Tvého manažera?</h4>
                            <p className="basicText">Číslo Tvého manažera najdeš ve Worku. Ulož si ho. Je důležité abys v připadě potřeby věděl, na koho se obrátit.</p>
                        </div>
                        <div className="col-sm-2" />
                        <div className="col-sm-5">
                            <h4 className="generalSubTitleStyle">Dovolená</h4>
                            <p className="basicText">Dovolenou hlaš s předstihem a nezadávej zpětně. Půl den není problém, jen uveď do poznámky informaci od-do, nebo dopoledne/odpoledne.</p>
                        </div>
                    </div>
                    <div className="col-sm-12 text-center">
                        <h4 className="generalSubTitleStyle">Nevíš si s něčím rady? Obrať se na BBC.</h4>
                        <p className="basicText"><b>Anet tel. 730 127 285</b></p>
                    </div>
                </div>
            </div>
        </div>*/
    /*<div className="pageCon backgroundHistoryCorner2">
            <div className="container-fluid containerCenterFixed">
                <div className="row">
                    <div className="col-sm-1"/>
                    <div className="col-sm-10">
                        <div className="row">
                            <div className="col-sm-12 mb-2 text-center">
                                <ReactWOW animation="fadeIn">
                                    <img src={sticker} className="it_stickerBasics" alt="basics sticker"/>
                                </ReactWOW>
                                <ReactWOW animation="fadeInDown">
                                    <h2 className="generalTitleStyle text-uppercase mt-3">basics</h2>
                                </ReactWOW>
                            </div>
                        </div>
                        <div className="col-sm-1"/>
                    </div>
                    <div className="basicsHide">
                        <div className="row">
                            <div className="col-sm-2"/>
                            <div className="col-sm-8">
                                <div className="row">
                                    <div className="col-sm-12 text-center">
                                        <p className="generalParagStyle">
                                            <b>Ulož si číslo na svého manažera. Je důležité, abys v případě potřeby věděl, na koho se obrátit.</b>
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 text-center">
                                        <h4 className="generalSubTitleStyle">Nevíš si s něčím rady? Obrať se na BBC.</h4>
                                        <p id="AnetTel" className="generalParagStyle">
                                            <b>Anet tel. 730 127 285</b>
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 text-center">
                                        <h4 className="generalSubTitleStyle">
                                            Kde si vykázat docházku? Kde si zadat dovolenou? Nebo kde najít to číslo na Tvého manažera?
                                        </h4>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <ul className="text-justify">
                                                    <li className="generalParagStyle">
                                                        Work neboli firemní intranet obsahuje vše, co potřebuješ a co by Tě mohlo zajímat.
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="col-sm-6">
                                                <ul className="text-justify">
                                                    <li className="generalParagStyle">
                                                        Ulož si číslo na svého manažera. Je důležité abys v případě potřeby věděl, na koho se obrátit.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 text-center">
                                        <h4 className="generalSubTitleStyle">Docházka a dovolená jsou dvě D, která si prosím pamatuj.</h4>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <ul className="text-justify">
                                                    <li className="generalParagStyle">Docházka se doplňuje poctivě a včas. Že se blíží poslední den v měsíci ti připomene info mail. Když
                                                        nevíme, kolik hodin jsi odpracoval, tak nevíme, kolik peněz Ti poslat, logické že?
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="col-sm-6">
                                                <ul className="text-justify">
                                                    <li className="generalParagStyle">Dovolenou hlaš s předstihem a nezadávej zpětně. Půl den není problém, jen uveď do poznámky
                                                        informaci od-do, nebo dopoledne/odpoledne. Samostatný půlden identifikuj v poznámce
                                                        dopoledne/odpoledne nebo od-do.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-2">
                                <button type="button" id="buttonID" onClick={() => {mouseMoveOut()}} className="nextButton nextButtonPrinciples">
                                    <svg id="arrow-forward" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                                        <path id="Shape" d="M11.5,0,9.488,2.013l8.05,8.05H0v2.875H17.538l-8.05,8.05L11.5,23,23,11.5Z"
                                              fill="#42A8D6"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>*/
    );
}