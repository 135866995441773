import React from "react";

import sticker3 from "../obrazky/Sticker3.png";
import ReactWOW from "react-wow";
export function BP_PrinciplesMG(props){
    props.handleLocal(27);
    function mouseMoveOut(){
        if(!document.getElementById("buttonID").classList.contains("nextButtonMouseMoveOut")) {
            document.getElementById("buttonID").setAttribute("class", "nextButton nextButtonPrinciples nextButtonMouseMoveOut");
            setTimeout(function () {
                props.handleChange(9);
            }, 1300);
        }
    }
    document.addEventListener('keydown',nextPage);
    function nextPage(e){
        if(e.keyCode === 39 && localStorage.getItem('OBPV') === "27"){
            mouseMoveOut();
        }
    }
    return(
        <div className="pageCon backgroundDC">
            <div className="container-fluid containerCenterFixed p-5">
                <div className="row">
                    <div className="col-sm-12 text-center mb-4">
                        <ReactWOW animation="fadeInDown">
                            <h2 className="text-uppercase generalTitleStyle">bluepool principles</h2>
                        </ReactWOW>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-2"/>
                    <div className="col-sm-4 text-center">
                        <ReactWOW animation="fadeInLeft">
                            <img src={sticker3} className="ESstickerMG" alt="Meet & Greet sticker" />
                        </ReactWOW>
                    </div>
                    <div className="col-sm-4 text-center">
                        <div className="ESparagStyle">
                            <ReactWOW animation="fadeIn" delay="900ms">
                                <h4 className="EStitle ESparagStyleTitle text-uppercase">Meet & greet</h4>
                                <ul className="text-justify">
                                    <li className="principlesParagStyle">
                                        Jsme konzultanti a pracujeme převážně u našich klientů. Co z toho vyplývá?
                                        Že se všichni dohromady moc často nepotkáváme. Protože je to ale důležité, pořádáme často společné akce.
                                        Naše akce jsou po práci.
                                    </li>
                                    <li className="principlesParagStyle">
                                        Máme pravidelné čtvrteční obědy, kde to nemá být o tom, na co zrovna máme chuť,
                                        ale o setkání s ostatními. Každý <b>čtvrtek</b> oběd pořádá jeden z nás.
                                        Tak si udělej v kalendáři poznámku a pokud je oběd blízko Tvého pracoviště, určitě doraž.
                                        Kdy jsi na řadě Ty, zjistíš ve Worku. Na oběd zveš vždy celou firmu, a to pomocí Google kalendáře a e-mailu
                                        <a href="mailto:bluepool@blue-pool.cz"> bluepool@blue-pool.cz</a>.
                                    </li>
                                    <li className="principlesParagStyle">
                                        Je důležité <b>znát</b> ostatní <b>kolegy</b> ve firmě, tak tu příležitost nepromeškej.
                                    </li>
                                </ul>
                            </ReactWOW>
                        </div>
                    </div>
                    <div className="col-sm-2 text-center">
                        <button type="button" id="buttonID" onClick={() => mouseMoveOut()} className="nextButton nextButtonNWO">
                            <svg id="arrow-forward" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                                <path id="Shape" d="M11.5,0,9.488,2.013l8.05,8.05H0v2.875H17.538l-8.05,8.05L11.5,23,23,11.5Z"
                                      fill="#42A8D6"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}