import React from "react";

import ReactWOW from "react-wow";
import sticker22 from "../obrazky/benefits-22.png";

export function BP_PrinciplesPL2(props){
    props.handleLocal(28);
    function mouseMoveOut(){
        if(!document.getElementById("buttonID").classList.contains("nextButtonMouseMoveOut")) {
            document.getElementById("buttonID").setAttribute("class", "nextButton nextButtonPrinciples nextButtonMouseMoveOut");
            setTimeout(function () {
                props.handleChange(20);
            }, 1300);
        }
    }
    document.addEventListener('keydown',nextPage);
    function nextPage(e){
        if(e.keyCode === 39 && localStorage.getItem('OBPV') === "28"){
            mouseMoveOut();
        }
    }
    return (
        <div className="pageCon backgroundDC">
            <div className="container-fluid containerCenterFixed p-5">
                <div className="row">
                    <div className="col-sm-12 text-center mb-4">
                        <ReactWOW animation="fadeInDown">
                            <h2 className="text-uppercase generalTitleStyle">bluepool principles</h2>
                        </ReactWOW>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-2"/>
                    <div className="col-sm-4 text-center">
                        <ReactWOW animation="fadeInLeft">
                            <img src={sticker22} className="ESstickerMG" alt="Project life sticker"/>
                        </ReactWOW>
                    </div>
                    <div className="col-sm-4 text-center">
                        <div className="ESparagStyle">
                            <ReactWOW animation="fadeIn" delay="900ms">
                                <ul className="text-justify">
                                    <li className="principlesParagStyle">
                                        Pokud nemůžeš podat 100 % výkon, hned o tom <b>informuj</b>.
                                    </li>
                                    <li className="principlesParagStyle">
                                        Pokud si potřebuješ vzít <b>dovolenou</b>, nejprve si to vyjednej <b>na projektu</b>. Po schválení si zadej dovolenou do <b>Worku</b>,
                                        kde Ti ji schválí Tvůj manažer v BluePoolu.
                                    </li>
                                    <li className="principlesParagStyle">
                                        Jsi vždy vhodně <b>oblečen</b>. Platí, že konzultanti by měli být oblečeni o trochu lépe než zaměstnanci klienta.
                                        Většinou není potřeba to přehánět, obleky úplně nenosíme. Pro konkrétní situaci a klienta se klidně přijď poradit.
                                    </li>
                                </ul>
                            </ReactWOW>
                        </div>
                    </div>
                    <div className="col-sm-2 text-center">
                        <button type="button" id="buttonID" onClick={() => {mouseMoveOut()}} className="nextButton nextButtonPrinciples">
                            <svg id="arrow-forward" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                                <path id="Shape" d="M11.5,0,9.488,2.013l8.05,8.05H0v2.875H17.538l-8.05,8.05L11.5,23,23,11.5Z"
                                      fill="#42A8D6"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}