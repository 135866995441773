import React from "react";

import Aneta from "../FotkyLudi/Aneta.jpg";
import Mikulas from "../FotkyLudi/Mikulas.jpg";

export function BBC(props){
    props.handleLocal(23);
    return (
        <div className="pageCon HR_BG">
            <div className="container-fluid containerCenterFixed">
                <div className="row">
                    <div className="col-sm-12">
                        <h1 className="generalTitleStyle text-uppercase text-center sekcePartnersPosition">
                            Sekce BBC
                        </h1>
                        <hr className="customHrCharacters"/>
                    </div>
                </div>
                <div className="row centerIMG">
                    <div className="col-sm-6">
                        <div className="flip-card pulseCustom">
                            <div className="flip-card-inner">
                                <div className="flip-card-front">
                                    <img src={Aneta} className="mainCharFlip" alt="Avatar"/>
                                </div>
                                <div className="flip-card-back">
                                    <div className="flipCardBackPadding">
                                        <h1 className="titlePartners">Aneta Hudcová</h1>
                                        <p>We love that woman</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="flip-card pulseCustom">
                            <div className="flip-card-inner">
                                <div className="flip-card-front">
                                    <img src={Mikulas} className="mainCharFlip" alt="Avatar"/>
                                </div>
                                <div className="flip-card-back">
                                    <div className="flipCardBackPadding">
                                        <h1 className="titlePartners">Mikuláš Hapka</h1>
                                        <p>We love that man</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}