import React from 'react';
import ReactWOW from 'react-wow';

export function BP_History(props){
    props.handleLocal(2);
    function mouseMoveOut() {
        if(!document.getElementById("buttonID").classList.contains("nextButtonHistoryMoveOut")) {
            document.getElementById("buttonID").setAttribute("class", "nextButton nextButtonPrinciples nextButtonHistoryMoveOut");
            setTimeout(function () {
                props.handleChange(3);
            }, 1300);
        }
    }
    document.addEventListener('keydown',nextPage);
    function nextPage(e){
        if(e.keyCode === 39 && localStorage.getItem('OBPV') === "2"){
            mouseMoveOut();
        }
    }
    return (
        <div className="pageCon backgroundHistoryCorner">
            <div className="container containerCenterFixed">
                <div className="row">
                    <div className="col-sm-1"/>
                    <div className="col-sm-10">
                        <div className="row">
                            <div className="col-sm-12 mb-4 text-center">
                                <ReactWOW animation="fadeIn">
                                    <h2 className="generalTitleStyle text-uppercase">Brief History of BluePool</h2>
                                </ReactWOW>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 text-center">
                                <ReactWOW animation="fadeInDown">
                                    <h4 className="generalSubTitleStyle">Jak to celé začalo?</h4>
                                    <p className="generalParagStyle text-center">Pár z nás se potkalo v korporaci, kde jsme spolu několik let pracovali a jednoho dne nám přišlo,
                                        že bychom spolu chtěli dělat dál. Jen někde jinde. Tak vznikl BluePool.</p>
                                </ReactWOW>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 text-center">
                                <ReactWOW animation="fadeInDown" delay="300ms">
                                    <h4 className="generalSubTitleStyle">Proč se jmenujeme BluePool?</h4>
                                    <p className="generalParagStyle">Slovo POOL jsme si přinesli z předchozí práce. Označoval se tak tým lidí, který jsme tam vytvořili.
                                    A máme rádi modrou.</p>
                                </ReactWOW>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 text-center">
                                <ReactWOW animation="fadeInDown" delay="600ms">
                                    <h4 className="generalSubTitleStyle">A kdo stál u zrodu BluePoolu?</h4>
                                    <p className="generalParagStyle">Tomáš spolu se Šárkou a Honzou postavili BluePool tak, jak ho můžeš poznat dnes. Samozřejmě
                                        tu je ještě mnoho dalších důležitých lidí. Ale to už necháme na organizační strukturu,
                                        kterou najdeš na další straně.</p>
                                </ReactWOW>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-1">
                        <button id="buttonID" onClick={() => {mouseMoveOut()}} className="nextButton nextButtonHistory">
                            <svg id="arrow-forward" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                                <path id="Shape" d="M11.5,0,9.488,2.013l8.05,8.05H0v2.875H17.538l-8.05,8.05L11.5,23,23,11.5Z"
                                      fill="#42A8D6"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}