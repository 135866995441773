import React from "react";
import {createUser} from '../JS/JS';

export function Form(props){
    props.handleLocal(25);
    function loadNext(){
        if(document.getElementById('login').value !== "") {//pokud není vyplněn input, nepustí se další stránka
            if(document.getElementById('login').value.length <= 4) {//pokud uživatel zadal více jak 4 znaky, nepustí ho dále
                localStorage.setItem("userID", document.getElementById('login').value);//uloží do lokálního užložiště login
                createUser();//založí uživatele s loginem
                setTimeout(function () {
                    props.handleChange(1);
                }, 1300);
            }else{
                alert("Zadaný login je příliš dlouhý! Můžete zadat maximálně 4 znaky");//pokud je login moc dlouhý
            }
        }else{
            alert("Prosím vyplňte svůj login pro pokračování");//pokud uživatel nevyplní login, vyhodí mu upozornění
        }
    }
    document.addEventListener('keydown',nextPage);
    function nextPage(e){
        if(e.keyCode === 39 && localStorage.getItem('OBPV') === "25"){
            loadNext();
        }
    }
    return (
        <div className="pageCon">
            <div className="containerCenterFixed">
                <div className="row">
                    <div className="col-sm-12 text-center">
                        <h1 className="generalTitleStyle">Zadejte svůj login</h1>
                    </div>
                </div>
                <div className="row text-center">
                    <form className="formCenter">
                        <input type="text" placeholder="Váš login" name="login" id="login" required/>
                    </form>
                </div>
                <div className="row">
                    <div className="col-sm-12 text-center mt-5">
                        <button type="submit" className="buttonQForm" onClick={() => {loadNext()}}>Pokračovat</button>
                    </div>
                </div>
            </div>
        </div>
    );
}