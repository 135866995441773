import React from 'react';
import { getQnA, postAnswers } from '../JS/QandA';

export function BP_QuizEnd(props){
    props.handleLocal(21);
    getQnA(4);
    function nextPage(){//metoda pro načtení další stránky
        if(postAnswers(4)) {//zavolá metodu, pokud je metoda true, přejde na další stránku
        setTimeout(function () {
            props.handleChange(14);
            },1300);
        }
    }
    document.addEventListener('keydown',keyClick);
    function keyClick(e){
        if(e.keyCode === 39 && localStorage.getItem('OBPV') === "21"){
            nextPage();
        }
    }
    return (
        <div className="pageCon Kviz_BG">
            <div className="container-fluid containerCenterFixed">
                <div className="row mb-2">
                    <div className="col-sm-12 text-center">
                        <h1 className="generalTitleStyle">Quiz</h1>
                        <h4 className="generalSubTitleStyle">Last but not least</h4>
                    </div>
                </div>
                <div id="Question" className="row">
                </div>
                <div id="AnswerRow" className="row">
                </div>
                <div id="Question2" className="row">
                </div>
                <div id="AnswerRow2" className="row">
                </div>
                <div className="row">
                    <div className="col-sm-12 text-center mt-5">
                        <button type="submit" className="buttonQForm" onClick={() => nextPage()}>Odeslat</button>
                    </div>
                </div>
            </div>
        </div>
    );
}