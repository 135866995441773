import React from "react";

import Martin from "../FotkyLudi/Martin.jpg";
import Honza from "../FotkyLudi/Honza.jpg";

export function DevTeam(props){
    props.handleLocal(24);
    return (
        <div className="pageCon DevTeam_BG">
            <div className="container-fluid containerCenterFixed">
                <div className="row">
                    <div className="col-sm-12">
                        <h1 className="generalTitleStyle text-uppercase text-center sekcePartnersPosition">
                            Sekce ICT a Dev Team
                        </h1>
                        <hr className="customHrCharacters"/>
                    </div>
                </div>
                <div className="row centerIMG">
                    <div className="col-sm-6">
                        <div className="flip-card pulseCustom">
                            <div className="flip-card-inner">
                                <div className="flip-card-front">
                                    <img src={Honza} className="mainCharFlip" alt="Avatar"/>
                                </div>
                                <div className="flip-card-back">
                                    <div className="flipCardBackPadding">
                                        <h1 className="titlePartners">Jan Menčík</h1>
                                        <p>We love that man</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="flip-card pulseCustom">
                            <div className="flip-card-inner">
                                <div className="flip-card-front">
                                    <img src={Martin} className="mainCharFlip" alt="Avatar"/>
                                </div>
                                <div className="flip-card-back">
                                    <div className="flipCardBackPadding">
                                        <h1 className="titlePartners">Martin Sládek</h1>
                                        <p>We love that man</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}