import React from "react";

import benefit2 from "../obrazky/benefits-02.png";
import ReactWOW from "react-wow";

export function BP_EmployeeSweetsNWO(props){
    props.handleLocal(7);
    function mouseMoveOut() {
        if(!document.getElementById("buttonID").classList.contains("nextButtonHistoryMoveOut")) {
            document.getElementById("buttonID").setAttribute("class", "nextButton nextButtonPrinciples nextButtonHistoryMoveOut");
            setTimeout(function () {
                props.handleChange(6);
            }, 1300);
        }
    }
    document.addEventListener('keydown',nextPage);
    function nextPage(e){
        if(e.keyCode === 39 && localStorage.getItem('OBPV') === "7"){
            mouseMoveOut();
        }
    }
    return (
        <div className="pageCon backgroundES">
            <div className="container-fluid containerCenterFixed">
                <div className="row">
                    <div className="col-sm-12 text-center">
                        <h2 className='generalTitleStyle text-uppercase mb-4'>Employee sweets</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-2"/>
                    <div className="col-sm-4 text-center">
                        <ReactWOW animation='fadeInLeft'>
                            <img src={benefit2} className="ESstickerNWO" alt=""/>
                        </ReactWOW>
                    </div>
                    <div className="col-sm-4 text-center">
                        <div className="ESparagStyleNWO">
                            <ReactWOW animation="fadeIn" delay="900ms">
                                <h4 className="EStitle ESparagStyleTitle text-uppercase">new worlds opening</h4>
                                <ul className="text-justify">
                                    <li className="ESparagStyle">
                                        Chceš poznat cokoliv nového v kultuře, gastronomii, naučit se pít dobrou kávu, hrát golf nebo se jakkoliv překonat? Tuhle službu Ti můžeme prokázat.
                                        Stačí se účastnit všech našich akcí nebo své přání opět sdělit BBC týmu.
                                    </li>
                                    <li className="ESparagStyle">
                                        Dvakrát do roka pořádáme výjezdový teambuilding, kde se často dostáváme za své hranice,
                                        překonáváme se. A to nás baví. Každé cca 3 týdny se potkáváme u zábavných aktivit, často takových, které bychom si sami tak skvěle neužili, někdy ani nezažili.
                                    </li>
                                </ul>
                            </ReactWOW>
                        </div>
                    </div>
                    <div className="col-sm-2">
                        <button id="buttonID" type="button" onClick={() => {mouseMoveOut()}} className="nextButton nextButtonNWO">
                            <svg id="arrow-forward" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                                <path id="Shape" d="M11.5,0,9.488,2.013l8.05,8.05H0v2.875H17.538l-8.05,8.05L11.5,23,23,11.5Z"
                                      fill="#42A8D6"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}