let $ = require('jquery');

$(document).ready(function () {
    //metoda se volá při prvním načtení stránky
    /*if(localStorage.getItem("sess") === "null" || localStorage.getItem("sess") === ""){
        localStorage.setItem("sess","notset");
    }
    getSessID(localStorage.getItem("sess"));*/
});


export function createUser(){//metoda která se zavolá po zadání uživatelského loginu
    let settings = {//zašle na server informaci, že uživatel začal RUN, pošle userID
        "url": "https://test1.iswork.cz/work_onb/index.php",
        "method": "POST",
        "timeout": 0,
        "headers": {
            "Content-Type": "application/x-www-form-urlencoded"
        },
        "data": {
            "userid": localStorage.getItem("userID"),
            "type": "start"
        }
        };
    $.ajax(settings).done(function (res) {

    });
}

export function endUser(){//metoda která se zavolá po ukončení procházení celého onboarding webu, zaznamená čas
    if(localStorage.getItem("userID") !== null || localStorage.getItem("userID") !== ""){//pokud existuje lokální proměnná login
        let settings = {//zašle informaci serveru, že uživatel ukončil běh, pošle mu userID, server uloží čas
            "url": "https://test1.iswork.cz/work_onb/index.php",
            "method": "POST",
            "timeout": 0,
            "headers": {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            "data": {
                "userid": localStorage.getItem("userID"),
                "type": "end"
            }
        };
        $.ajax(settings).done(function (res){

        });
        localStorage.removeItem("userID");//odendá lokální proměnou login, aby se nemohl čas ukončení opět přepsat
        localStorage.setItem("OBPV",0);//nastaví se výchozí stránka
    }
}

/*function getSessID(sessID){
    let settings = {
        "url": "https://test1.iswork.cz/work_onb/index.php",
        "method": "GET",
        "timeout": 0,
        "headers": {
            "Content-Type": "application/x-www-form-urlencoded"
        },
        "data":{
            "sess": sessID
        }

    };
    $.ajax(settings).done(function (res) {

        if(res){

            localStorage.setItem("sess",res);
        }
    });
}*/