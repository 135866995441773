import React from "react";

import instagram from '../obrazky/benefits-09.png'
import facebook from '../obrazky/benefits-10.png'
import linkedIn from '../obrazky/benefits-11.png'
import ReactWOW from "react-wow";

export function BP_SocialMedia(props){
    props.handleLocal(13);
    function mouseMoveOut() {
        if(!document.getElementById("buttonID").classList.contains("nextButtonSocialMediaMoveOut")) {
            document.getElementById("buttonID").setAttribute("class", "nextButton nextButtonPrinciples nextButtonSocialMediaMoveOut");
            setTimeout(function () {
                props.handleChange(21);
            }, 1300);
        }
    }
    document.addEventListener('keydown',nextPage);
    function nextPage(e){
        if(e.keyCode === 39 && localStorage.getItem('OBPV') === "13"){
            mouseMoveOut();
        }
    }
    return(
        <div className="pageCon backgroundHistoryCorner">
            <div className="container-fluid containerCenterFixed">
                <div className="row">
                    <div className="col-sm-2"/>
                    <div className="col-sm-8">
                        <div className="row">
                            <div className="col-sm-12 text-center">
                                <ReactWOW animation="fadeInDown">
                                    <h2 className="generalTitleStyle">BluePool Social Media</h2>
                                </ReactWOW>
                            </div>
                        </div>
                        <div className="row mt-3 mb-3">
                            <div className="col-sm-12 text-center">
                                <ReactWOW animation="fadeIn" duration="3000ms">
                                    <p className="generalParagStyle">
                                        Jsme na sociálních sítích. Proč? Abychom šířili, co je pro nás důležité a tím přitahovali lidi na stejné vlně.
                                        Nedáváme náborové inzeráty, nové kolegy najímáme většinou na základě doporučení našich stávajících zaměstnanců nebo právě přes sociální sítě.
                                        Nejlepší vizitkou firmy je, když její obsah sdílí vlastní zaměstnanci. Pomoz nám s osvětou a lajkuj. A kde všude nás najdeš?
                                    </p>
                                </ReactWOW>
                            </div>
                        </div>
                        <div className="row text-center">
                            <div className="col-sm-4">
                                <ReactWOW animation="fadeInUp" delay="500ms">
                                    <a href="https://www.instagram.com/bluepool_consulting/" target="_blank" rel="noopener noreferrer">
                                        <img src={instagram} className="socialStickers pulseCustom" alt=""/>
                                    </a>
                                </ReactWOW>
                            </div>
                            <div className="col-sm-4">
                                <ReactWOW animation="fadeInUp" delay="1000ms">
                                    <a href="https://www.facebook.com/BluePoolCZ/" target="_blank" rel="noopener noreferrer">
                                        <img src={facebook} className="socialStickers pulseCustom" alt=""/>
                                    </a>
                                </ReactWOW>
                            </div>
                            <div className="col-sm-4">
                                <ReactWOW animation="fadeInUp" delay="1500ms">
                                    <a href="https://www.linkedin.com/company/bluepool-s-r-o-" target="_blank" rel="noopener noreferrer">
                                        <img src={linkedIn} className="socialStickers pulseCustom" alt=""/>
                                    </a>
                                </ReactWOW>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-2">
                        <button type="button" id="buttonID" onClick={() => {mouseMoveOut()}} className="nextButton nextButtonSocialMedia">
                            <svg id="arrow-forward" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                                <path id="Shape" d="M11.5,0,9.488,2.013l8.05,8.05H0v2.875H17.538l-8.05,8.05L11.5,23,23,11.5Z"
                                      fill="#42A8D6"/>
                            </svg>
                        </button>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-10"/>
                    <div className="col-sm-2">
                        {/*<button type="button" id="buttonID" onClick={() => {props.handleChange(14)}} className="nextButton nextButtonSocialMedia">
                            <svg id="arrow-forward" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                                <path id="Shape" d="M11.5,0,9.488,2.013l8.05,8.05H0v2.875H17.538l-8.05,8.05L11.5,23,23,11.5Z"
                                      fill="#42A8D6"/>
                            </svg>
                        </button>*/}
                    </div>
                </div>
            </div>
        </div>
    );
}