import React from "react";

import Tomas from "../FotkyLudi/Tomas.jpg";
import Sarka from "../FotkyLudi/Sarka.jpg";

export function MainCharactersTaS(props){
    props.handleLocal(22);
    return (
        <div className="pageCon backgroundClients">
            <div className="container-fluid containerCenterFixed">
                <div className="row">
                    <div className="col-sm-12">
                        <h1 className="generalTitleStyle text-uppercase text-center sekcePartnersPosition">
                            Sekce partners
                        </h1>
                        <hr className="customHrCharacters"/>
                    </div>
                </div>
                <div className="row centerIMG">
                    <div className="col-sm-6">
                        <div className="flip-card pulseCustom">
                            <div className="flip-card-inner">
                                <div className="flip-card-front">
                                    <img src={Tomas} className="mainCharFlip" alt="Avatar"/>
                                </div>
                                <div className="flip-card-back">
                                    <div className="flipCardBackPadding">
                                        <h1 className="titlePartners">Tomáš Novák</h1>
                                        <p>We love that guy</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="flip-card pulseCustom">
                            <div className="flip-card-inner">
                                <div className="flip-card-front">
                                    <img src={Sarka} className="mainCharFlip" alt="Avatar"/>
                                </div>
                                <div className="flip-card-back">
                                    <div className="flipCardBackPadding">
                                        <h1 className="titlePartners">Šárka Rolčíková</h1>
                                        <p>We love that woman</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}