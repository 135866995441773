import React from "react";
import {endUser} from "../JS/JS";

import logoBP from  "../obrazky/BluePool_one_row.png";

export function BP_LnL(props){
    props.handleLocal(14);
    /*function exitAPP() {
        localStorage.removeItem("OBPV");
        props.handleChange(0);
    }*/
    return(
        <div className="pageCon backgroundHistory wave1" onLoad={() => {endUser()}}>
            <div className="backgroundHistory2 wave2"/>
            <div className="backgroundHistory3 wave3"/>
            <div className="container containerCenterFixed">
                <div className="row">
                    <div className="col-sm-12 text-center text-uppercase">
                        <h2 className="generalTitleStyle">last but not least</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 text-center">
                        <p className="generalParagStyle">
                            Dozvěděl ses všechno, co jsi potřeboval? Chybělo Ti tu něco? Dej nám vědět.
                        </p>
                        <p className="generalParagStyle">Jsme tady - naše největší radost je,
                            když jsou všichni spokojení a chceme vědět, jestli můžeme ještě něco zlepšit.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 text-center">
                        <p className="generalParagStyle">Díky a přejeme Ti, ať se Ti u nás moc líbí!</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 text-right">
                        <img src={logoBP} className="logoBP" alt=""/>
                    </div>
                </div>
                {/*<div className="row p-0">
                    <div className="col-sm-12 text-center">
                        <button type="button" id="exitButton" onClick={() => {exitAPP()}} className="nextButton exitButton"><i className="fa fa-times"/></button>
                    </div>
                </div>*/}
            </div>
        </div>
    );
}