import React, {Component} from 'react';
import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';

import './style.css';
import './parallax';
import './script';

import './Animate/animate.css';
import './effects';
import './JS/JS';

import {HomePage} from './pages/HomePage';
import {BP_Manifest} from './pages/BP_Manifest';
import {BP_History} from './pages/BP_History';
import {BP_KeyWords} from './pages/BP_KeyWords';
//BP_EmployeeSweets
import {BP_EmployeeSweetsDB} from './pages/BP_EmployeeSweetsDB';
import {BP_EmployeeSweetsBTC} from './pages/BP_EmployeeSweetsBTC';
import {BP_EmployeeSweetsENG} from './pages/BP_EmployeeSweetsENG';
import {BP_EmployeeSweetsStyleCareer} from './pages/BP_EmployeeSweetsStyleCareer';
import {BP_EmployeeSweetsNWO} from './pages/BP_EmployeeSweetsNWO';
//BP_Princilples
import {BP_Principles} from './pages/BP_Principles';
import {BP_PrinciplesDC} from './pages/BP_PrinciplesDC';
import {BP_PrinciplesPL} from './pages/BP_PrinciplesPL';
import {BP_PrinciplesMG} from './pages/BP_PrinciplesMG';
import {BP_PrinciplesPL2} from "./pages/BP_PrinciplesPL2";

import {BP_OurClients} from './pages/BP_OurClients';
import {BP_Basics} from './pages/BP_Basics';
import {BP_SocialMedia} from './pages/BP_SocialMedia';
import {BP_LnL} from './pages/BP_LnL';
import {BP_MainCharacters} from "./pages/BP_MainCharacters";
//Quiz
import {BP_QuizHistoryShortcuts} from './pages/BP_QuizHistory&Shortcuts';
import {BP_QuizEmployeeSweets} from './pages/BP_QuizEmployeeSweets';
import {BP_QuizPrinciples} from './pages/BP_QuizPrinciples';
import {BP_QuizEnd} from './pages/BP_QuizEnd';
//MainCharacters
import {MainCharactersTaS} from './pages/MainCharactersTaS';
import {BBC} from './pages/BBC';
import {DevTeam} from "./pages/DevTeam";
//Form
import {Form} from './pages/Form';
//AllQuestions
import {AllQuestions} from "./pages/AllQuestions";
//ShowAnswers
import {ShowAnswers} from "./pages/ShowAnswers";

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: localStorage.getItem("OBPV") !== null ? Number(localStorage.getItem("OBPV")) : 0
        }
    }

    componentDidMount() {
        const queryParms = new URLSearchParams(window.location.search);//získá parametry z URL
        if(queryParms.get('allQuestions') === 'show'){//pokud parametr allQuestions obsahuje show
            this.handlePageChange(29);//zobrazí stránku se všemi otázkami
        }
        //pokud parametr Answers obsahuje show a je uveden parametr login
        if(queryParms.get("Answers") === "show" && queryParms.get("login") != null){
            this.handlePageChange(26);//zobrazí odpovědi uživatele
        }
        if(localStorage.getItem("OBPV") === null){//pokud v lokálním uložišti se nenachází proměnná OBPV
            localStorage.setItem("OBPV", 0);//vytvoří proměnou s nulovou hodnotou
        }
    }

    handleRender(){
            switch (this.state.page) {//podle proměnné OBPV zobrazí stránky
                // eslint-disable-next-line react/jsx-pascal-case
                case 1: return <BP_Manifest handleLocal={this.handleLocalStorage.bind(this)} handleChange={this.handlePageChange.bind(this)} />;
                // eslint-disable-next-line react/jsx-pascal-case
                case 2: return <BP_History handleLocal={this.handleLocalStorage.bind(this)} handleChange={this.handlePageChange.bind(this)} />;
                // eslint-disable-next-line react/jsx-pascal-case
                case 3: return <BP_KeyWords handleLocal={this.handleLocalStorage.bind(this)} handleChange={this.handlePageChange.bind(this)} />;

                //BP_EmployeeSweets
                // eslint-disable-next-line react/jsx-pascal-case
                case 4: return <BP_EmployeeSweetsDB handleLocal={this.handleLocalStorage.bind(this)} handleChange={this.handlePageChange.bind(this)} />;
                // eslint-disable-next-line react/jsx-pascal-case
                case 17: return <BP_EmployeeSweetsBTC handleLocal={this.handleLocalStorage.bind(this)} handleChange={this.handlePageChange.bind(this)} />;
                // eslint-disable-next-line react/jsx-pascal-case
                case 5: return <BP_EmployeeSweetsENG handleLocal={this.handleLocalStorage.bind(this)} handleChange={this.handlePageChange.bind(this)} />;
                // eslint-disable-next-line react/jsx-pascal-case
                case 7: return <BP_EmployeeSweetsNWO handleLocal={this.handleLocalStorage.bind(this)} handleChange={this.handlePageChange.bind(this)} />;
                // eslint-disable-next-line react/jsx-pascal-case
                case 6: return <BP_EmployeeSweetsStyleCareer handleLocal={this.handleLocalStorage.bind(this)} handleChange={this.handlePageChange.bind(this)} />;

                //BP_Princilples
                // eslint-disable-next-line react/jsx-pascal-case
                case 8: return <BP_PrinciplesPL handleLocal={this.handleLocalStorage.bind(this)} handleChange={this.handlePageChange.bind(this)} />;
                // eslint-disable-next-line react/jsx-pascal-case
                case 28: return <BP_PrinciplesPL2 handleLocal={this.handleLocalStorage.bind(this)} handleChange={this.handlePageChange.bind(this)} />;
                // eslint-disable-next-line react/jsx-pascal-case
                case 9: return <BP_PrinciplesDC handleLocal={this.handleLocalStorage.bind(this)} handleChange={this.handlePageChange.bind(this)} />;
                // eslint-disable-next-line react/jsx-pascal-case
                case 10: return <BP_Principles handleLocal={this.handleLocalStorage.bind(this)} handleChange={this.handlePageChange.bind(this)} />
                // eslint-disable-next-line react/jsx-pascal-case
                case 27: return <BP_PrinciplesMG handleLocal={this.handleLocalStorage.bind(this)} handleChange={this.handlePageChange.bind(this)} />;

                //Other
                // eslint-disable-next-line react/jsx-pascal-case
                case 11: return <BP_OurClients handleLocal={this.handleLocalStorage.bind(this)} handleChange={this.handlePageChange.bind(this)} />;
                // eslint-disable-next-line react/jsx-pascal-case
                case 12: return <BP_Basics handleLocal={this.handleLocalStorage.bind(this)} handleChange={this.handlePageChange.bind(this)} />;
                // eslint-disable-next-line react/jsx-pascal-case
                case 13: return <BP_SocialMedia handleLocal={this.handleLocalStorage.bind(this)} handleChange={this.handlePageChange.bind(this)} />;
                // eslint-disable-next-line react/jsx-pascal-case
                case 14: return <BP_LnL handleLocal={this.handleLocalStorage.bind(this)} handleChange={this.handlePageChange.bind(this)} />;
                // eslint-disable-next-line react/jsx-pascal-case
                case 16: return <BP_MainCharacters handleLocal={this.handleLocalStorage.bind(this)} handleChange={this.handlePageChange.bind(this)} />;

                //Quiz
                // eslint-disable-next-line react/jsx-pascal-case
                case 18: return <BP_QuizHistoryShortcuts handleLocal={this.handleLocalStorage.bind(this)} handleChange={this.handlePageChange.bind(this)} />;
                // eslint-disable-next-line react/jsx-pascal-case
                case 19: return <BP_QuizEmployeeSweets handleLocal={this.handleLocalStorage.bind(this)} handleChange={this.handlePageChange.bind(this)} />;
                // eslint-disable-next-line react/jsx-pascal-case
                case 20: return <BP_QuizPrinciples handleLocal={this.handleLocalStorage.bind(this)} handleChange={this.handlePageChange.bind(this)} />;
                // eslint-disable-next-line react/jsx-pascal-case
                case 21: return <BP_QuizEnd handleLocal={this.handleLocalStorage.bind(this)} handleChange={this.handlePageChange.bind(this)} />;

                //MainCharacters
                case 22: return <MainCharactersTaS handleLocal={this.handleLocalStorage.bind(this)} handleChange={this.handlePageChange.bind(this)} />;
                case 23: return <BBC handleLocal={this.handleLocalStorage.bind(this)} handleChange={this.handlePageChange.bind(this)} />;
                case 24: return <DevTeam handleLocal={this.handleLocalStorage.bind(this)} handleChange={this.handlePageChange.bind(this)} />;

                //Form
                case 25: return <Form handleLocal={this.handleLocalStorage.bind(this)} handleChange={this.handlePageChange.bind(this)} />

                //ShowAnswers
                case 26: return <ShowAnswers handleLocal={this.handleLocalStorage.bind(this)} handleChange={this.handlePageChange.bind(this)} />

                //AllQuestions
                case 29: return <AllQuestions handleLocal={this.handleLocalStorage.bind(this)} handleChange={this.handlePageChange.bind(this)} />

                //Main On Boarding Page
                default: return <HomePage handleLocal={this.handleLocalStorage.bind(this)} handleChange={this.handlePageChange.bind(this)} />
        }
    }
    handlePageChange(param){
        this.setState({page: param})
    }

    handleLocalStorage(param){
        localStorage.setItem("OBPV", param);
    }

  render() {
    return (
        <TransitionGroup>
            <CSSTransition
                key={this.state.page}
                timeout={450}
                classNames="fade"
            >
                <div>
                    {
                        this.handleRender()
                    }
                </div>
            </CSSTransition>
        </TransitionGroup>
    );
  }
}
export default App;
