let $ = require('jquery');
$(document).ready(function(){
    let screenWidth = $(window).width();
    if (screenWidth < 426){
        $('.ekometalVideo').removeAttr('autoPlay');
        $('.ekometalVideo').removeAttr('muted');
    } else {
        // $('.ekometalVideo').attr('autoPlay');
        $('.ekometalVideo').removeAttr('controls');
    }
});
