import React from "react";
import ReactWOW from 'react-wow';


import benefit3 from "../obrazky/benefits-01.png";

export function BP_EmployeeSweetsBTC(props){
    props.handleLocal(17);
    function mouseMoveOut() {
        if(!document.getElementById("buttonID").classList.contains("nextButtonHistoryMoveOut")) {
            document.getElementById("buttonID").setAttribute("class", "nextButton nextButtonPrinciples nextButtonHistoryMoveOut");
            setTimeout(function () {
                props.handleChange(5);
            }, 1300);
        }
    }
    document.addEventListener('keydown',nextPage);
    function nextPage(e){
        if(e.keyCode === 39 && localStorage.getItem('OBPV') === "17"){
            mouseMoveOut();
        }
    }
    return (
        <div className="pageCon backgroundHistoryCorner">
            <div className="container-fluid containerCenterFixed">
                <div className="row">
                    <div className="col-sm-12 text-center">
                        <ReactWOW animation='fadeInDown' delay="200ms">
                            <h2 className='generalTitleStyle text-uppercase mb-4'>Employee sweets</h2>
                        </ReactWOW>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-2"/>
                    <div className="col-sm-4 text-center">
                        <div className="imgCenteredBTC">
                            <ReactWOW animation='fadeInLeft'>
                                    <img src={benefit3} className="ESBTCsticker" alt=""/>
                            </ReactWOW>
                        </div>
                    </div>
                    <div className="col-sm-4 text-center">
                        <div className="ESparagStyleBTC">
                            <ReactWOW animation="fadeIn" delay="900ms">
                                <h4 className="EStitle text-uppercase">Business Trip Challenge</h4>
                                <ul className="text-justify">
                                    <li className="ESparagStyle">
                                        Žiješ naší firmou (no jasně, že jo) a snažíš se ji posouvat dál? Za každou pomoc BluePoolu jsme vděční, a tak ji oceňujeme.
                                    </li>
                                    <li className="ESparagStyle">
                                        Doporučíš nového kolegu nebo klienta a body/kredity na sebe nenechají dlouho čekat. A když se staneš jedním za dvou nejlepších,
                                        jedeš na business výlet do exkluzivní destinace i s průvodcem a kapesným.
                                    </li>
                                </ul>
                            </ReactWOW>
                        </div>
                    </div>
                    <div className="col-sm-2">
                        {/*<ReactWOW animation='fadeIn' delay="500ms">*/}
                            <button id="buttonID" type="button" onClick={() => {mouseMoveOut()}} className="nextButton nextButtonNWO">
                                <svg id="arrow-forward" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                                    <path id="Shape" d="M11.5,0,9.488,2.013l8.05,8.05H0v2.875H17.538l-8.05,8.05L11.5,23,23,11.5Z"
                                          fill="#42A8D6"/>
                                </svg>
                            </button>
                        {/*</ReactWOW>*/}
                    </div>
                </div>
            </div>
        </div>
    );
}