import React from "react";

// import sticker2 from "../obrazky/Sticker2.png"
import dresscode from "../soubory/dresscode.pdf"
import benefit6 from "../obrazky/benefits-03.png"
import ReactWOW from "react-wow";

export function BP_PrinciplesDC(props){
    props.handleLocal(9);
    function mouseMoveOut() {
        if(!document.getElementById("buttonID").classList.contains("nextButtonMouseMoveOut")) {
            document.getElementById("buttonID").setAttribute("class", "nextButton nextButtonPrinciples nextButtonMouseMoveOut");
            setTimeout(function () {
                props.handleChange(8);
            }, 1300);
        }
    }
    document.addEventListener('keydown',nextPage);
    function nextPage(e){
        if(e.keyCode === 39 && localStorage.getItem('OBPV') === "9"){
            mouseMoveOut();
        }
    }
    return(
        <div className="pageCon backgroundDC">
            <div className="container-fluid containerCenterFixed p-5">
                <div className="row">
                    <div className="col-sm-12 text-center mb-4">
                        <ReactWOW animation="fadeInDown">
                            <h2 className="text-uppercase generalTitleStyle">bluepool principles</h2>
                        </ReactWOW>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-2 text-center">
                    </div>
                    <div className="col-sm-4 text-center">
                        <ReactWOW animation="fadeInLeft">
                            <img src={benefit6} className="benefitPrinciplesMiddleDC" alt=""/>
                        </ReactWOW>
                    </div>
                    <div className="col-sm-4 text-center">
                        <div className="principlesCenteredDC">
                            <ReactWOW animation="fadeIn" delay="900ms">
                                <h4 className="text-uppercase PPtitle mb-4">DressCode</h4>
                                <ul className="text-justify">
                                    <li className="principlesParagStyle">
                                        Jednou z věcí, kterou se odlišujeme od konkurence je, že se jako konzultanti nejen chováme, ale tak i vypadáme.
                                        To, jak jsi oblečen, dělá dojem. Okolí s Tebou jinak komunikuje, jinak na Tebe nahlíží.
                                    </li>
                                    <li className="principlesParagStyle">
                                        Ale žádný strach, rádi Ti s tím poradíme. A neděláme to chvilku, takže máme vyzkoušenou i spoustu míst,
                                        kam vyrazit a umíme udělat hodně muziky za málo peněz.
                                    </li>
                                    <li className="principlesParagStyle">
                                        Také pro Tebe máme příručku <a rel="noopener noreferrer" target="_blank" href={dresscode} className="linkDC">DressCode</a>
                                        , abys oblékání případně zvládl i sám.
                                    </li>
                                </ul>
                            </ReactWOW>
                        </div>
                    </div>
                    <div className="col-sm-2 text-center">
                        <button type="button" id="buttonID" onClick={() => {mouseMoveOut()}} className="nextButton nextButtonPrinciples">
                            <svg id="arrow-forward" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                                <path id="Shape" d="M11.5,0,9.488,2.013l8.05,8.05H0v2.875H17.538l-8.05,8.05L11.5,23,23,11.5Z"
                                      fill="#42A8D6"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}