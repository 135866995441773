import React from "react";

//Images
import sticker from '../obrazky/IT_Sticker.png';
import allianz from '../loga/allianz_logo.png';
import kb from '../loga/kb.png';
import hyposervis from '../loga/hyposervis.png';
// import louka from '../loga/louka_transparent.png';
import rb from '../loga/rb.png';
import provident from '../loga/provi.png';
import stavebka from '../loga/sscs_1.png';
// import neaB from '../loga/nea.png';
import udhpsh from '../loga/urad2.png';
import photonE from '../loga/photonEnergy.png';

export function BP_OurClients(props) {
    props.handleLocal(11);
    function mouseMoveOut() {
        if(!document.getElementById("buttonID").classList.contains("nextButtonHistoryMoveOut")) {
            document.getElementById("buttonID").setAttribute("class", "nextButton nextButtonClients nextButtonHistoryMoveOut");
            setTimeout(function () {
                props.handleChange(12);
            }, 1300);
        }
    }
    document.addEventListener('keydown',nextPage);
    function nextPage(e){
        if(e.keyCode === 39 && localStorage.getItem('OBPV') === "11"){
                mouseMoveOut();
        }
    }
    return (
        <div className="pageCon backgroundClients">
            <div className="container-fluid containerCenterFixed">
                <div className="row">
                    <div className="col-sm-12 mb-4 text-center">
                        <img src={sticker} className="it_sticker" alt=""/>
                        <h2 className="generalTitleStyle text-uppercase mt-3">Klienti</h2>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 ">
                        <p className="generalParagStyle text-center">Jako správný konzultant BluePoolu bys měl vědět, kde všude působíme.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-2"/>
                    <div className="col-sm-8">
                        <div className="row">
                            <div className="col-sm-3 generalLogoStyle text-center">
                                <img className="client_logo" src={allianz} alt="Allianz"/>
                            </div>
                            <div className="col-sm-3 generalLogoStyle text-center">
                                <img className="client_logo" src={kb} alt="KB"/>
                            </div>
                            <div className="col-sm-3 generalLogoStyle text-center">
                                <img className="client_logo" src={hyposervis} alt="Hyposervis"/>
                            </div>
                            <div className="col-sm-3 generalLogoStyle text-center">
                                {/*<img src={louka} alt=""/>*/}
                                <img className="client_logo" src={udhpsh} alt="Úřad pro dohled nad hospodařením politický stran a politických hnutí"/>
                            </div>
                        </div>

                        <div className="row pt-4">
                            <div className="col-sm-3 generalLogoStyle text-center">
                                <img className="client_logo" src={rb} alt="Raiffeisen Bank"/>
                            </div>
                            <div className="col-sm-3 generalLogoStyle text-center">
                                <img className="client_logo" src={provident} alt="Provident"/>
                            </div>
                            <div className="col-sm-3 generalLogoStyle text-center">
                                <img className="client_logo" src={stavebka} alt="Stavební spořitelna"/>
                            </div>
                            <div className="col-sm-3 generalLogoStyle text-center">
                                {/*<img src={neaB} alt=""/>*/}
                                <img className="client_logo" src={photonE} alt="Photon Energy"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-2">
                        <button type="button" id="buttonID" onClick={() => {mouseMoveOut()}} className="nextButton nextButtonClients">
                            <svg id="arrow-forward" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                                <path id="Shape" d="M11.5,0,9.488,2.013l8.05,8.05H0v2.875H17.538l-8.05,8.05L11.5,23,23,11.5Z"
                                      fill="#42A8D6"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}