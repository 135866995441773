import React from 'react';
import "../JS/QandA";
import { getQnA, postAnswers } from '../JS/QandA';

export function BP_QuizHistoryShortcuts(props){
    props.handleLocal(18);
    getQnA(1);
    function nextPage(){//metoda pro načtení další stránky
        if(postAnswers(1)) {//zavolá metodu a pokud metoda vrátí true, načte se další stránka
            setTimeout(function () {
                props.handleChange(4);//načte další stránku
            }, 1300);
        }
    }
    document.addEventListener('keydown',keyClick);
    function keyClick(e){
        if(e.keyCode === 39 && localStorage.getItem('OBPV') === "18"){
            nextPage();
        }
    }
    return (
        <div className="pageCon Kviz_BG">
            <div className="container containerCenterFixed">
                <div className="row">
                    <div className="col-sm-12 text-center mb-2">
                        <h1 className="generalTitleStyle">Quiz</h1>
                        <h4 className="generalSubTitleStyle">History & Shortcuts</h4>
                    </div>
                </div>
                <div id="Question" className="row">
                </div>
                <div id="AnswerRow" className="row">
                </div>
                <div className="row">
                    <div className="col-sm-12 text-center mt-5">
                        <button type="submit" className="buttonQForm" onClick={() => {nextPage()}}>Odeslat</button>
                    </div>
                </div>
            </div>
        </div>
    );
}