import React from "react";
import {getAllQuestions} from "../JS/QandA";

export function AllQuestions(props){
    props.handleLocal(0);//nastaví nulovou hodnotu, pokud uživatel přenačte stránky bez parametrů v url, přesune ho zpět na hlavní stránku
    getAllQuestions();//zavolá metodu, která vypíše všechny otázky do divu s id Questions
    return (
        <div style={{margin: "2rem"}}>
            <div className="row">
                <div className="col-sm-12 text-center">
                    <h1 className="generalTitleStyle">Výpis všech otázek a správných odpovědí</h1>
                </div>
            </div>
            <div id="Question" className="row">
            </div>
        </div>
    );
}