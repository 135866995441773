import React from "react";

import benefit4 from "../obrazky/benefits-04.png";
import benefit5 from "../obrazky/benefits-07.png";
import ReactWOW from "react-wow";

export function BP_EmployeeSweetsENG(props){
    props.handleLocal(5);
    let $ = require('jquery');
    function mouseMoveOut() {
        if(!document.getElementById("buttonID").classList.contains("nextButtonHistoryMoveOut")) {
            document.getElementById("buttonID").setAttribute("class", "nextButton nextButtonPrinciples nextButtonHistoryMoveOut");
            setTimeout(function () {
                props.handleChange(7);
            }, 1300);
        }
    }
    document.addEventListener('keydown',nextPage);
    function nextPage(e){
        if(e.keyCode === 39 && localStorage.getItem('OBPV') === "5"){
            mouseMoveOut();
        }
    }
    function changeState(button, item){
        if(!item.hasClass("fadeInDownCustom")){
            item.removeClass("fadeInDownCustom");
            item.css("visibility","visible").addClass("fadeInDownCustom");
            button.removeClass("pulseCustom");
        }else{
            item.removeClass("fadeInDownCustom");
            item.addClass("fadeOutUpCustom");
            setTimeout(function(){
               item.css("visibility","hidden");},
               2000
            );
            button.addClass("pulseCustom");
        }
    }
    $(document).ready(function () {
        $(".ESENGsticker").click(function () {
            changeState($(".ESENGsticker"),$(".ESENGli"));
        });

        $(".ESimproveSticker").click(function () {
            changeState($(".ESimproveSticker"),$(".ESimproveLi"));
        });
    });
    return (
        <div className="pageCon backgroundHistoryCorner2">
            <div className="container-fluid containerCenterFixed">
                <div className="row">
                    <div className="col-sm-12 text-center">
                        <ReactWOW animation='fadeInDown' delay="200ms">
                            <h2 className='generalTitleStyle text-uppercase mb-4'>Employee sweets</h2>
                        </ReactWOW>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-2"/>
                    <div className="col-sm-4 text-center">
                        <h4 className="EStitle text-uppercase">We speak English. Do you?</h4>
                        <img src={benefit4} className="ESsticker ESENGsticker pulseCustom" alt="English Sticker"/>
                        <ul className="text-justify">
                            <li className="ESparagStyle ESENGli">
                                Wanna be better in English? No problem! Máme vlastního učitele angličtiny. Je to rodilý mluvčí. A když chodíš pravidelně, lekce máš zadarmo. Ať už jsi v jakémkoli týmu.
                            </li>
                        </ul>
                    </div>
                    <div className="col-sm-4 text-center">
                        <h4 className="EStitle text-uppercase">Improve yourself!</h4>
                        <img src={benefit5} className="ESsticker ESimproveSticker pulseCustom" alt="Improve yourself sticker"/>
                        <ul className="text-justify">
                            <li className="ESparagStyle ESimproveLi">
                                Chceš si pravidelně rozšiřovat obzory? Můžeš. Pravidelně pořádáme vzdělávací kurzy s odborníky z praxe. Máš zájem o něco konkrétního? Tak víš co? Opět stačí dát vědět BBC. Budeme rádi.
                            </li>
                        </ul>
                    </div>
                    <div className="col-sm-2">
                        <button id="buttonID" type="button" onClick={() => {mouseMoveOut()}} className="nextButton nextButtonNWO">
                            <svg id="arrow-forward" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                                <path id="Shape" d="M11.5,0,9.488,2.013l8.05,8.05H0v2.875H17.538l-8.05,8.05L11.5,23,23,11.5Z"
                                      fill="#42A8D6"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}